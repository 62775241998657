import * as React from "react"
import { ChakraProvider, theme } from "@chakra-ui/react"
// import { ColorModeSwitcher } from "./ColorModeSwitcher"
import Login from "./auth/Login"

export const App = () => (
  <ChakraProvider theme={theme}>
    <Login />
  </ChakraProvider>
)
